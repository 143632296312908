import React, { ReactElement, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  SelectDetailsButton,
  SelectMenuHeader,
  SEmptyState,
  SSelectDropdown
} from '@setvi/shared/components';

interface SSelectProps<T> {
  data: T[];
  selected: T[];
  label: string | ReactElement;
  placeholder?: string;
  keyGetter: (option: T) => string | number;
  onSearch?: (value: string) => void;
  renderElement: (option: T, closeDropdown?: () => void) => React.ReactNode;
  onClear: () => void;
  width?: number;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateImage?: string;
  customAnchor?: React.ReactElement;
  customHeader?: React.ReactElement;
  portal?: boolean;
  marginTop?: number;
}

const SSelect = <T,>({
  data,
  selected,
  label,
  placeholder,
  onSearch,
  renderElement,
  keyGetter,
  onClear,
  width = 300,
  emptyStateTitle,
  customAnchor,
  emptyStateSubtitle,
  emptyStateImage,
  portal = true,
  customHeader = null,
  marginTop = 0
}: SSelectProps<T>) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const showData = data.length > 0;

  const closeDropdown = () => {
    setAnchor(null);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <SSelectDropdown
      marginTop={marginTop}
      width={width}
      disablePortal={!portal}
      anchor={
        customAnchor ? (
          <Box onClick={e => setAnchor(e?.currentTarget)}>{customAnchor}</Box>
        ) : (
          <SelectDetailsButton
            width={width}
            {...(typeof label === 'string'
              ? { label }
              : { renderTitle: () => label })}
            title={placeholder}
            titleHighligted={selected?.length > 0}
            onClick={e => setAnchor(e?.currentTarget)}
            open={Boolean(anchor)}
          />
        )
      }
      anchorElement={anchor}
      onClose={closeDropdown}
      content={
        <Box>
          {customHeader || (
            <SelectMenuHeader
              searchValue={searchValue}
              placeholder="Search..."
              title="Filter by"
              search={onSearch !== undefined}
              clearButton
              handleClear={handleClearSearch}
              handleChange={handleSearchChange}
            />
          )}

          {showData ? (
            data.map(option => (
              <Box key={keyGetter(option)}>
                {renderElement(option, closeDropdown)}
              </Box>
            ))
          ) : (
            <SEmptyState
              width={100}
              title={emptyStateTitle}
              subTitle={emptyStateSubtitle}
              imageSrc={emptyStateImage}
              imageAlt="No Results Found..."
            />
          )}
        </Box>
      }
    />
  );
};

export default SSelect;
